body,
#root {
    margin: 0;
    font-family: MetropolisRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    font-size: 16px;
    color: #0C182D;
    overflow-x: hidden;
}

/* width */
*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #F8F7FC;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #d8d8d8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

h1 {
    font-weight: 600;
    font-size: 24px;
}

h2 {
    font-weight: 600;
    font-size: 20px;
}

h3 {
    font-weight: 500;
    font-size: 18px;
}

a {
    text-decoration: none;
}

